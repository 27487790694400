<template>
    <pagination title="Details" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :hasEntities="true" :checkSize="false" :with-edit-icon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Name
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="name"
                        :action="editing? updateAction: null"
                        fieldId="name"
                        :show-label="false"
                        v-model="updateActionData['name']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Prefix
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="prefix"
                        :action="editing? updateAction: null"
                        fieldId="prefix"
                        :show-label="false"
                        v-model="updateActionData['prefix']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Logo
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="logo"
                        :action="editing? updateAction: null"
                        fieldId="logo"
                        :show-label="false"
                        v-model="updateActionData['logo']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Employer Code
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="employer_code"
                        :action="null"
                        fieldId="employer_code"
                        :show-label="false">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Company Registration Number
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="company_registration_number"
                        :action="editing? updateAction: null"
                        fieldId="company_registration_number"
                        :show-label="false"
                        v-model="updateActionData['company_registration_number']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Vat Number
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="vat_number"
                        :action="editing? updateAction: null"
                        fieldId="vat_number"
                        :show-label="false"
                        v-model="updateActionData['vat_number']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Registered Address
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="address"
                        :action="editing? updateAction: null"
                        fieldId="address"
                        :show-label="false"
                        v-model="updateActionData['address']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Is Approved
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="approved"
                        :action="editing? updateAction: null"
                        fieldId="is_approved"
                        :show-label="false"
                        v-model="updateActionData['is_approved']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Account Used
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="account_used"
                        :action="editing? updateAction: null"
                        fieldId="account_used"
                        :show-label="false"
                        v-model="updateActionData['account_used']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Direct Supplier
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="direct_supplier_id"
                        :action="editing? updateAction: null"
                        fieldId="direct_supplier_id"
                        :show-label="false"
                        v-model="updateActionData['direct_supplier_id']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Department
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="department"
                        :action="editing? updateAction: null"
                        fieldId="department"
                        :show-label="false"
                        v-model="updateActionData['department']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Service Fee (%)
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="service_fee"
                        :action="editing? updateAction: null"
                        fieldId="service_fee"
                        :show-label="false"
                        v-model="updateActionData['service_fee']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Procurement Package
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="procurement_package"
                        :action="editing? updateAction: null"
                        fieldId="procurement_package"
                        :show-label="false"
                        v-model="updateActionData['procurement_package']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    On-Site Selfie Package
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="oss_package"
                        :action="editing? updateAction: null"
                        fieldId="oss_package"
                        :show-label="false"
                        v-model="updateActionData['oss_package']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Apps Package
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="apps_package"
                        :action="editing? updateAction: null"
                        fieldId="apps_package"
                        :show-label="false"
                        v-model="updateActionData['apps_package']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Is Restricted
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="is_restricted"
                        :action="editing? updateAction: null"
                        fieldId="is_restricted"
                        :show-label="false"
                        v-model="updateActionData['is_restricted']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Admin That Can Login
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="main_admin_when_restricted"
                        :action="editing? updateAction: null"
                        fieldId="main_admin_when_restricted"
                        :show-label="false"
                        v-model="updateActionData['main_admin_when_restricted']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Restricted Message To Admin
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="restricted_message"
                        :action="editing? updateAction: null"
                        fieldId="restricted_message"
                        :show-label="false"
                        v-model="updateActionData['restricted_message']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
  name: 'ClientDetailsPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: false
      },
      editing: {
        type: Boolean,
          default: false
      },
      updateAction: {
        type: Object,
          default: null
      },
      updateActionData:{
        type: Object,
          default: null
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
