<template>
    <pagination title="Projects" :response="response" :entities="entities" header-colour-class="bg-transparent">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Address</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Note</th>
        </template>
        <template v-slot:items>
            <tr v-for="entity in entities">
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <div>{{ entity.entity.properties.get('name') }}
                    </div>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    {{ entity.entity.properties.get('address') }}
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    {{ entity.entity.properties.get('note') }}
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
export default {
  name: 'ClientProjectsPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false
      }
    },
    components: {DownloadPdfIconWithLink, FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
    },
  props: {
    entities: {},
      response: {
        type: Object,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        showCreatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('created_at'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        },
        showUpdatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('updated_at_unformatted'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        },
        altViewClicked(){
            this.$emit('altViewClicked');
            this.viewUser = false;
        }
    }
}
</script>
